.titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.iconTitle {
    margin-right: 8px;
    color: #0B060F
}

.imageTitle {
    font-family: "OutfitBold" !important;
    font-size: 18px !important;
}

.uploadImageContainer {
    width: 480px;
    height: 397px;
    border-radius: 8px;
    border: 2px solid var(--bosscat-grey-400);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 42px;
    cursor: pointer;
}

.image {
    width: 480px;
    height: 397px;
    border-radius: 8px;
    object-fit: cover;
}

.uploadIcon {
    width: 48px !important;
    height: 48px !important;
    color: #0B060F;
}

.uploadText {
    font-family: "OutfitBold" !important;
    font-size: 18px !important;
    margin: 8px 0;
}

.rowItem {
    // max-width: 480px;
}